import React, {useState} from "react";
import Slider from "react-slick";
import {  useSelector } from "react-redux";
import styled from 'styled-components'

const SING = styled.img`
  height: 200px;
  float: left;
  margin-left: 10px;
  cursor: pointer;
`;
const SONG = styled.div`
`;
const NUMB = styled.div`
  background: url(/Users/0/numb.png) no-repeat;
  left: 69px;
  color: azure;
  width: 34px;
  height: 20px;
  background-size: 100% 100%;
  margin: auto;
`;
export const Sing = () => {
  const song  = useSelector(state => state.post.song)

  const [Code, setCode] = useState(0)
  const [Sing, setSing] = useState(0)

  const imageClick = (code, numb) => {
    setCode(code) 
    setSing(numb) 
  }
  if (Code === 0 && song.length > 1) {
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <img
          className={className}
          style={{ ...style, display: "block", width: "36px", height: "36px", right: "0px", zIndex:"1", border: "1px solid white", background: "white"}}
          onClick={onClick}
          src={"/Users/0/rt.png"}
          alt="up"
        />
      );
    }
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <img
          className={className}
          style={{ ...style, display: "block", width: "36px", height: "36px", left: "2px", zIndex:"1", border: "1px solid white", background: "white"}}
          onClick={onClick}
          src={"/Users/0/lt.png"}
          alt="dn"
        />
      );
    }
    var settings = {
      infinite: true,
      slidesToShow: 4,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }
    return (
      <div>
        <Slider {...settings}>
          {song.map(poster => (
            <div id ="crew">
              {poster[4]+1 ?  <NUMB>{poster[4]+1}</NUMB> : ""}
              <div style={{ height: "19%"}}>{poster[2]}</div>
              <input
                type = "image"
                className="image"
                src={'http://kshisa.ru/images/7/'+ poster[3] + 'p2.jpg'}
                alt= ''
                onClick={() => imageClick(1, poster[4])}
              />
              <div style={{ margin: "4px"} }>{poster[0]}</div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
  if (Code === 1 || song.length === 1) {
    let click
    if (song.length > 1) {
      click = () => imageClick(0)
    }
    else {
      click = ''
    }
    return (
      <div>
        <SING
          src = {'http://kshisa.ru/images/7/'+ song[Sing][3] + 'p1.jpg'}
          alt = ''
          onClick={click}
        />
        <SONG>{song[Sing][2]}</SONG>
        <SONG>{song[Sing][0]}</SONG>
        <SONG>{'(' + song[Sing][1] + ')'}</SONG>
      </div>
    )
  }
}
export default (Sing)