import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import {entr} from '../actions/card'


export const Pass = () => {
  const dispatch = useDispatch()
  const [pass1, setPass1] = useState("")
  const [pass2, setPass2] = useState("")
  const [pass3, setPass3] = useState("")
  const [pass4, setPass4] = useState("")
  const [pass5, setPass5] = useState("")

  const logn = {
    top: '5px',
    position: 'relative',
    height: '335px',
    width: '100%',
    float: 'left'
  }
  const radn = {
    margin: '8px'
  }
  const blok = {
    height: '41px',
    margin: '5px'
  }
  const buts = {
    display: 'block',
    float: 'left',
    width: '47px',
    border: 'groove',
    margin: '7px'
  }
  let pass
  const vals = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J', 'K', 'L', 'M', 'N']

  if (pass1 && pass2 && pass3 && pass4 && pass5) {
    pass = <button onClick = {() => dispatch(entr(pass1 + pass2 + pass3 + pass4 + pass5))}>
            {pass1 + pass2 + pass3 + pass4 + pass5}
           </button>
  }
  else {
    pass = pass1 + pass2 + pass3 + pass4 + pass5
  }

  return (
    <div>
      <div style = {logn}>
        <hr/>
        <p style = {blok}>
        {vals.map(val => (
          <label key = {val} style = {buts}>
            <input style = {radn}
                    type = "radio"
                   value = {val}
                 onClick = {e => setPass1(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr/>
        <p style = {blok}>
        {vals.map(val => (
          <label key = {val} style = {buts}>
            <input style = {radn}
                    type = "radio" 
                   value = {val}
                 onClick = {e => setPass2(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr/>
        <p style = {blok}>
        {vals.map(val => (
          <label key = {val} style = {buts}>
            <input style = {radn}
                    type = "radio"
                   value = {val}
                 onClick = {e => setPass3(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr/>
        <p style = {blok}>
        {vals.map(val => (
          <label key = {val} style = {buts}>
            <input style = {radn}
                    type = "radio" 
                   value = {val}
                 onClick = {e => setPass4(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr/>
        <p style = {blok}>
        {vals.map(val => (
          <label key = {val} style = {buts}>
            <input style = {radn}
                    type = "radio"
                   value = {val}
                 onClick = {e => setPass5(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr/>
      </div>
      {pass}
    </div>
  )
}

export default (Pass)