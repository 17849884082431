import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {updateplacard} from '../actions/card'

export const Awad = () => {
  const dispatch = useDispatch()
  const awar = useSelector(state => state.post.awar)
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", right: "-8px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/rt.png"}
        alt="up"
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", right: "-8px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/lt.png"}
        alt="dn"
      />
    );
  }
  var settings = {
    infinite: true,
    slidesToShow: 7,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true
  }
  return (
    <div>
      <Slider {...settings}>
        {awar.map(priz => (
          <label key = {priz[0]}>
              <img className = "bill"
                         src = {'Users/0/' + priz[1] +'.png'}
                         alt = ''
                     onClick =  {() => dispatch(updateplacard(7, priz[0]))}
              />
          </label>
        ))}
      </Slider>
    </div> 
  )
}
export default (Awad)